.home-lista {
	@apply w-full h-full pt-20 pb-40;

	@screen lg {
		padding-top: 50px;
	}

	// .h3 {
	// 	@apply text-center pb-4;
	// 	color: $light-color;

	// 	@screen lg {
	// 		@apply pb-8;
	// 	}
	// }

	.home-lista__filtros {
		@apply hidden;

		.filtros-mobile {
			@apply w-full mx-auto;
			max-width: 500px;

			@screen xl {
				display: none;
			}

			&__botones {
				@apply flex justify-between items-center py-4;

				.boton {
					@apply flex flex-row items-center px-4 py-2 rounded-full mr-2 transition-all;
					border: 2px solid $light-color;
					background-color: white;
					color: $dark-color;

					.icon {
						@apply mr-2;

						img {
							width: 15px;
						}
					}

					&:hover {
						filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
					}
				}
			}
		}

		.filtros-desktop {
			@apply hidden;
			&__option {
				@apply flex items-center justify-center;
			}

			@screen xl {
				@apply grid w-full gap-2 grid-cols-6;

				.select {
					@apply px-4 py-1 rounded-full;
					border: 2px solid $light-color;
					background-color: white;
					color: $dark-color;
				}

				.boton {
					@apply flex flex-row items-center px-4 py-1 rounded-full transition-all;
					border: 2px solid $light-color;
					background-color: $light-color;
					color: $white-color;

					&:hover {
						filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
					}
				}
			}
		}
	}

	.home-lista__vehiculos {
		@apply grid grid-cols-1 justify-center pt-12;

		row-gap: 50px;
		column-gap: 50px;

		.car {
			@apply w-full mx-auto;
			max-width: 380px;
			text-align: left;
		}

		@screen md {
			grid-template-columns: repeat(2, 1fr);
		}
		@screen lg {
			grid-template-columns: repeat(3, 1fr);
		}
		@screen xl {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	.home-lista__pagination {
		padding-top: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: fit-content;
		margin: auto;

		span {
			font-family: 'VWHead Regular';
			font-size: 16px;
			font-weight: 400;
			line-height: 27px;
			letter-spacing: 0em;
			text-align: center;
			color: #7D7D7D;
			margin: auto 20px;
		}
		&__button {
			@apply flex flex-row items-center px-4 py-2 rounded-full transition-all;
			border: 2px solid $light-color;
			background-color: $light-color;
			color: $white-color;

			.icon {
				@apply mr-2;

				img {
					width: 15px;
				}
			}

			&:hover {
				filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
			}
			&:disabled {
				border: 2px solid $light-color;
				background-color: #fff;
				color: $dark-color;
				cursor: not-allowed;

				&:hover {
					filter: unset;
				}
			}
		}
	}
}
