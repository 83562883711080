.cta{
  @include padding(30px, 0, 30px, 0);
  margin-left: 30px;
  height: auto;
  @screen md {
    margin-left: 30px;
    margin-right: 30px;
  }
  @screen lg{
    height: 328px;
    margin-left: 40px;
    margin-right: 40px;
  }
  @screen xl{
    height: 328px;
  }
  @screen 2xl {
    margin-left: 80px;
    margin-right: 80px;
    height: 427px
  }
  &__item{
    @include padding(23px, 39px, 23px, 39px);
    @apply cursor-pointer bg-dark text-center radius-top-small radius-bottom-small;
    height: 100px;
    background-image: url(../../../public/images/bg-buttons-reflect.png);
    background-position: 100px -50px;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
    background-size: 1200px;
    overflow: hidden;
    min-width: 245px;
    @screen md {
      @include padding(32px, 35px, 32px, 35px);
      @apply text-left;
      height: 120px;
    }
    @screen xl {
      @include padding(35px, 35px, 35px, 35px);
      background-position: 180px 10px;
      background-size: 1300px;
      height: 128px;
    }
    @screen 2xl {
      @include padding(50px, 50px, 50px, 50px);
      height: 178px;
    }
    &__body{
      picture{
        margin-top: 24px;
        margin-bottom: 15px;
        @screen md {
          margin-top: 30px;
          margin-bottom: 25px;
        }
        @screen xl {
          margin-top: 50px;
          margin-bottom: 30px;
        }
      }
      p{
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 15px;
        @screen md {
          font-size: 16px;
          line-height: 20px;
        }
        @screen xl {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 20px;
        }
        @screen 2xl {
          margin-bottom: 25px;
        }
      }
      .btn{
        @include padding(4px, 0, 4px, 0);
        border: 1px solid #001F4E;
        @screen md {
          @include padding(7px, 0, 7px, 0);
          border-width: 2px;
        }
        @screen xl {
        }
        @screen 2xl {
          @include padding(10px, 0, 10px, 0);
        }
      }
    }
    &:hover{
      @apply bg-light;
    }
    &--hover{
      transition: height .3s;
      &:hover{
        transition: height .3s;
        height: 337px;
        z-index: 2;
        @screen xl {
          height: 527px;
        }
        @screen 2xl {
          height: 610px;
        }
      }
    }
  }
  @screen md {
    @include padding(100px, 0, 100px, 0);
  }
  @screen 2xl {
    @include padding(128px, 0, 121px, 0);
  }
}