.detalle-header {
  padding-top: 200px;

  &__title {
    @apply flex flex-col items-center;
    color: $light-color;

    .link {
      @apply ml-auto;
      color: $dark-color;
    }

    .link, .h1 {
      @apply py-2;
    }
  }
}