.related{
  background: #F6F6F6;
  @include padding(50px, 30px, 0, 30px);
  @screen md {
    @include padding(50px, 40px, 60px, 40px);
  }
  @screen xl {
    @include padding(60px, 60px, 80px, 60px);
  }
  @media (min-width: 1920px) {
    @include padding(70px, 80px, 80px, 80px);
  }
  .car{
    @screen md{
      min-width: 280px;
    }
    @screen lg{
      min-width: 265px;
    }
    &__image{
      @screen lg{
        height: 190px;
      }
    }
    &:nth-child(1){
      @screen md{
        margin-right: 15px;
      }
      @screen xl{
        margin-right: 30px;
      }
      @media (min-width: 1920px){
        margin-right: 40px
      }
    }
    &:nth-child(2){
      @screen md{
        margin-left: 7.5px;
        margin-right: 7.5px;
      }
      @screen xl{
        margin-left: 15px;
        margin-right: 15px;
      }
      @media (min-width: 1920px){
        margin-left: 30px;
        margin-right: 30px;
      }
    }
    &:nth-child(3){
      @screen md{
        margin-left: 15px;
      }
      @screen xl{
        margin-left: 30px;
      }
      @media (min-width: 1920px){
        margin-left: 40px;
      }
    }
    &__content{
      background-color: #FFFFFF;
    }
  }
  .carousel-root{
    .carousel-slider{
      padding-bottom: 130px;
      @screen md {
        padding-bottom: 0;
      }
    }
    .arrow-button{
      position: absolute;
      bottom: 60px;
      z-index: 1;
      &.next{
        left: calc(50% + 8px);
      }
      &.prev{
        left: calc(50% - 38px);
      }
    }
  }
  &__text{
    padding-bottom: 35px;
    @screen md{
      padding-bottom: 0;
    }
    @screen xl {
      max-width: 20%;
      margin-right: auto;
    }
    h3{
      @screen 2xl {
        @apply h3;
      }
    }
  }
  &__carousel{
    @screen xl {
      max-width: 75%;
      margin-right: 0;
    }
  }
}