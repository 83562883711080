.home-carousel {
	@apply w-full;
	margin-bottom: 0;
	color: $white-color;
	height: fit-content;
	padding-bottom: 250px;

	.carousel-root {
		background: linear-gradient(131.31deg, #00437a 0%, #001e50 100%);

		.carousel-slider,
		.slider-wrapper,
		.slider {
			height: 650px;
		}
		.carousel-slider,
		.slider-wrapper {
			overflow: visible;
		}
	}

	&__item {
		@apply flex items-center justify-center w-full h-full relative;
		padding: 0 30px;

		&__content {
			@apply w-full flex justify-center flex-col mx-auto;
			height: 350px;
			max-width: 300px;
			@screen lg{
				margin-top: auto !important;
			}
			.h1 {
				@apply py-4;
				text-align: left;
				@screen lg{
					max-width: 70%;
				}
				@screen 2xl{
					max-width: 80%;
				}
			}

			.carousel-button {
				@apply px-6 py-2 flex items-center justify-center transition-colors;

				color: $white-color;
				border: 2px solid $light-color;
				border-radius: 50px;

				&:hover {
					background-color: $light-color;
				}
			}
			.arrows {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px;

				button {
					width: 50px;
					height: 50px;
				}
			}
		}
		&__img {
			@apply absolute object-cover left-0 right-0 mx-auto w-full;

			top: 520px;
			max-width: 350px;
			border-radius: 20px;
			aspect-ratio: 1 / 1;
		}
	}

	@screen lg {
		padding-bottom: 0;
		.carousel-root {
			.carousel-slider,
			.slider-wrapper,
			.slider {
				height: 800px;
			}

			.home-carousel__item {
				@apply grid p-0 justify-items-center;
				grid-template-columns: 5fr 6fr;

				.home-carousel__item__content {
					@apply w-full m-0 px-8;
					max-width: 700px;
				}
				.home-carousel__item__img {
					@apply relative w-full h-full top-0 left-0 rounded-none;
					max-width: 100%;
					max-height: 800px;
				}
			}
		}
	}
	// @screen xl {
	// 	.home-carousel__item {
	// 		.home-carousel__item__content {
	// 			h3 {
	// 				font-size: 64px;
	// 				line-height: 78px;
	// 			}
	// 		}
	// 	}
	// }
}
