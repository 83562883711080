.testimonials{
  @include padding(80px, 30px, 80px, 30px);
  @screen xl{
    @include padding(100px, 40px, 100px, 40px);
  }
  @screen 2xl{
    @include padding(110px, 60px, 110px, 60px);
  }
  @media (min-width: 1920px){
    @include padding(110px, 80px, 110px, 80px);
  }
  &__text{
    @screen lg{
      min-width: 25%;
    }
    h3{
      margin-bottom: 30px;
      @screen xl{
        margin-bottom: 60px;
      }
    }
    p{
      margin-bottom: 30px;
      @screen lg{
        margin-bottom: 0;
      }
    }
  }
  &__list{
    &-item{
      margin-bottom: 30px;
      @include padding(30px, 30px, 30px, 30px);
      @screen lg{
        margin-bottom: 0px;
        min-width: 310px;
        margin-right: 30px
      }
      @screen xl{
        @include padding(40px, 25px, 40px, 25px);
        min-width: auto;
        margin-right: 15px;
      }
      @screen 2xl{
        @include padding(40px, 40px, 40px, 40px);
        min-width: auto;
        margin-right: 10px;
      }
      @media (min-width: 1920px){
        margin-right: 80px;
      }
      &:first-child{
        @screen lg{
          margin-left: 20px;
        }
        @screen xl{
          margin-left: 0;
        }
      }
      &:last-child{
        @screen lg{
          margin-right: 0;
        }
      }
      &--text{
        line-height: 20px;
        margin-bottom: 30px;
        @screen xl{
          margin-bottom: 30px;
          font-size: 15px;
        }
        @screen 2xl{
          margin-bottom: 42px;
          font-size: 16px;
        }
        @media (min-width: 1920px){
          font-size: 18px;
        }
        &::before{
          content: '';
          position: absolute;
          width: 36px;
          height: 3px;
          border-radius: 5px;
          background-color: #DEDEDE;
          bottom: -15px;
          left: calc(50% - 18px);
          @screen xl{
            bottom: -18px;
          }
        }
      }
      &--name{
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 18px;
        @screen 2xl{
          font-size: 30px;
          line-height: 32px;
        }
      }
      &--icon{
        margin-bottom: 5px;
        @screen 2xl{
          margin-bottom: 7px;
        }
      }
      &--used{
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }
}