/* ================ FONTS ============ */
@font-face {
  font-family: 'VWHead Bold';
    src: url('../../fonts/VWHead-Bold.otf') format('opentype');
    font-weight: 700;
}
@font-face {
  font-family: 'VWHead Regular';
    src: url('../../fonts/VWHead-Regular.otf') format('opentype');
    font-weight: 400;
}
@font-face {
  font-family: 'VWHead Light';
    src: url('../../fonts/VWHead-Light.otf') format('opentype');
    font-weight: 300;
}
// @font-face {
//   font-family: 'BebasNeue';
//   src:  url('./fonts/BebasNeue-Regular.eot');
//   src:  url('./fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
//         url('./fonts/BebasNeue-Regular.woff2') format('woff2'),
//         url('./fonts/BebasNeue-Regular.woff') format('woff'),
//         url('./fonts/BebasNeue-Regular.otf') format('opentype'),
//         url('./fonts/BebasNeue-Regular.ttf') format('truetype');
//   font-weight: 400;
// }
html, body, #root {
  font-family: "VWHead Regular", Sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 300;
  height: 100%;
}

/* ================ VARIABLES ============ */
$dark-color: #001F4E;
$light-color: #099AC8;
$white-color: #F6F6F6;
$gray-color: #7D7D7D;
$gray-light-color: #D7D7D7;

/* ================= CLASES ============= */
.container {
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    width: calc(100% - 60px);
  }
}

@media (min-width: 768px) {
  .container {
    width: calc(100% - 80px);
  }
}

@media (min-width: 1024px) {
  .container {
    width: calc(100% - 80px);
    max-width: calc(100% - 80px);
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: calc(100% - 120px);
  }
}
@media (min-width: 1441px) {
  .container {
    max-width: calc(100% - 140px);
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: calc(100% - 160px);
  }
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  // margin-left: -1rem;
  // margin-right: -1rem;
}

.h1{
  font-size: 36px;
  line-height: 40px;
  font-family: 'VWHead Light';
  font-weight: 300;
  @media (min-width: 768px){
    font-size: 42px;
    line-height: 48px;
  }
  @media (min-width: 1280px){
    font-size: 48px;
    line-height: 56px;
  }
  @media (min-width: 1536px){
    font-size: 64px;
    line-height: 78.02px;
  }
  strong{
    font-family: 'VWHead Bold';
    font-weight: 700;
  }
}
.h2{
  font-size: 35px;
  line-height: 39px;
  font-family: 'VWHead Light';
  font-weight: 300;
  @media (min-width: 768px){
    font-size: 42px;
    line-height: 48px;
  }
  @media (min-width: 1280px){
    font-size: 40px;
    line-height: 48px;
  }
  @media (min-width: 1536px){
    font-size: 62px;
    line-height: 66px;
  }
  strong{
    font-family: 'VWHead Bold';
    font-weight: 700;
  }
}
.h3{
  font-size: 36px; /* 26px */
  line-height: 43.88px;
  font-family: 'VWHead Light';
  font-weight: 300;
  @media (min-width: 768px){
    font-size: 38px;
    line-height: 42px;
  }
  @media (min-width: 1280px){
    font-size: 32px;
    line-height: 36px;
  }
  @media (min-width: 1536px){
    font-size: 46px;  /* 26px * 1.78 */
    line-height: 56.07px;
  }
  strong{
    font-family: 'VWHead Bold';
    font-weight: 700;
  }
}
.h4{
  font-size: 22px; /* 18px */
  line-height: 26.82px;
  font-family: 'VWHead Light';
  font-weight: 300;
  @screen md {
    font-size: 22px;
    line-height: 29px;
  }
  @media (min-width: 1280px){
    font-size: 24px;
    line-height: 28px;
  }
  @media (min-width: 1536px){
    font-size: 32px;  /* 18px * 1.78 */
    line-height: 39.01px;
  }
  strong{
    font-family: 'VWHead Bold';
    font-weight: 700;
  }
}

.f-light{
  font-family: 'VWHead Light';
  font-weight: 300;
}
.f-regular{
  font-family: 'VWHead Regular';
  font-weight: 400;
}
.f-bold{
  font-family: 'VWHead Bold';
  font-weight: 700;
}
.bg-light{
  background-color: $light-color;
}
.bg-dark{
  background-color: $dark-color
}
.bg-gray{
  background-color: $gray-color;
}
.bg-white{
  background-color: $white-color;
}
.bg-gray-light{
  background-color: $gray-light-color;
}
.c-light{
  color: $light-color;
}
.c-dark{
  color: $dark-color
}
.c-white{
  color: $white-color;
}
.c-gray{
  color: $gray-color;
}
.radius-top-small{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.radius-bottom-small{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.radius-top-medium{
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.radius-bottom-medium{
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}