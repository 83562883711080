.navbar {
	@apply fixed z-40;

	top: 0;
	left: 0;
	height: 120px;
	width: 100%;

	&-content {
		@apply flex items-center justify-between h-full w-full px-8;

		.navbar-logo {
			@apply px-2;

			img {
				max-width: 130px;
			}
		}
		.navbar-search {
			margin-left: auto;
		}
		.navbar-search,
		.navbar-hamburguer {
			@apply px-2;

			img {
				width: 25px;
			}
		}

		img {
			@apply w-full mx-auto;
		}
	}

	&-lines {
		@apply absolute w-full grid items-center;
		bottom: -25px;
		grid-template-columns: 5fr 70px 5fr;

		.line {
			height: 1px;
			width: 100%;
			background: white;
		}
		.logo {
			@apply w-full;

			img {
				@apply rounded-full;
				width: 50px;
				margin: auto;
				background: $dark-color;
				border: 2px solid $dark-color;
			}
		}

		@screen md {
			grid-template-columns: 8fr 70px 3fr;
		}
	}

	&-menu {
		@apply fixed w-full h-full z-50 text-white;
		transition: all ease-in 0.3s;
		top: 0;
		left: 0;
		max-height: 100vh;
		overflow-y: auto;
		background: linear-gradient(105.18deg, #00437a 0%, #001e50 100%);

		&__container {
			@apply relative w-full h-full grid text-left;
			grid-template-columns: 1fr;

			&--left {
				@apply w-full flex flex-col items-start px-8 py-40;

				.navbar-menu__title {
					@apply w-full py-4 mb-auto;

					.h3-subtitle {
						color: #099ac8;
					}
				}
				.navbar-menu__links {
					@apply w-full py-4 flex flex-col items-start;

					.navbar-link {
						@apply py-1;
						font-size: 24px;
						font-family: 'VWHead Light';
						transition: color ease 0.3s;

						&:hover {
							color: #099ac8;
						}

						@media (min-width: 768px) {
							font-size: 42px;
							line-height: 42px;
						}
						@media (min-width: 1280px) {
							font-size: 36px;
							line-height: 36px;
						}
						@media (min-width: 1536px) {
							font-size: 50px;
							line-height: 56.07px;
						}
					}
				}
			}

			&--right {
				@apply hidden;
			}

			@screen md {
				&--left {
					@apply px-32;
				}
			}
			@screen xl {
				grid-template-columns: 6fr 4fr;

				&--left {
					@apply px-12;
				}
				&--right {
					@apply flex flex-col justify-center items-start px-12 py-40;

					.item {
						@apply grid items-center justify-center py-4;
						grid-template-columns: 80px calc(100% - 80px);
						max-width: 400px;

						.image {
							@apply rounded-full flex items-center justify-center;
							width: 80px;
							height: 80px;
							background: linear-gradient(
								180deg,
								rgba(255, 255, 255, 0.5) 0%,
								rgba(255, 255, 255, 0) 81.77%
							);
						}
						.text {
							padding-left: 16px;

							&__h4 {
								@apply text-3xl;
							}
							&__p {
								@apply text-base text-left;
							}
						}
					}
				}
			}
		}

		&__close {
			position: absolute;
			width: 45px;
			height: 45px;
			top: 4.1%;
			right: 4.17%;
		}
	}
}
