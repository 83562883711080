.sell{
  flex-direction: column;
  @screen lg{
    flex-direction: row;
  }
  &__image{
    img{
      object-fit: cover;
      object-position: center;
      height: 100%;
    }
  }
  &__text{
    @include padding (50px, 0, 50px, 30px);
    @screen lg{
      min-width: 50%;
      @include padding (50px, 0, 50px, 40px);
    }
    @media (min-width: 1920px){
      @include padding (110px, 0, 120px, 80px);
    }
    h2{
      margin-bottom: 20px;
      @media (min-width: 1920px){
        margin-bottom: 50px;
      }
    }
    p{
      margin-bottom: 20px;
      @media (min-width: 1920px){
        font-size: 24px;
        line-height: 29.26px;
      }
    }
    .steps{
      padding-top: 80px;
      @screen lg{
        padding-top: 50px;
      }
      &__item{
        border: 3px solid $light-color;
        @include padding(30px, 20px, 30px, 20px);
        min-width: 230px;
        margin-right: 30px;
        @screen lg{
          @include padding(30px, 30px, 30px, 30px);
          min-width: 250px;
          margin-right: 40px;
        }
        @screen xl{
          @include padding(45px, 35px, 45px, 35px);
        }
        @media (min-width: 1920px){
          min-width: 290px;
          margin-right: 50px;
        }
        &:last-child{
          @include padding(30px, 15px, 30px, 15px);
          @screen lg{
            @include padding(30px, 20px, 30px, 20px);
          }
          @media (min-width: 1920px){
            @include padding(45px, 30px, 45px, 30px);
          }
        }
        figure{
          margin-bottom: 10px;
          @screen xl{
            margin-bottom: 15px;
          }
          @media (min-width: 1920px){
            margin-bottom: 23px;
          }
        }
        h5{
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px;
          @screen xl{
            margin-bottom: 15px;
          }
          @media (min-width: 1920px){
            font-size: 26px;
            line-height: 30px;
            margin-bottom: 22px;
          }
        }
        p{
          margin-bottom: 0;
          font-size: 14px;
          line-height: 20px;
          @screen lg{
            font-size: 16px;
            line-height: 22px;
          }
          @media (min-width: 1920px){
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
    .buttons{
      display: none;
      top: 50px;
      right: 30px;
      cursor: pointer;
      @screen lg{
        display: flex;
        top: 20px;
        right: 40px;
      }
      @media (min-width: 1920px){
        right: 80px;
      }
      &--left{
        margin-right: 15px;
        img{
          max-width: 20px;
          @screen xl{
            max-width: auto;
          }
        }
      }
      &--right{
        img{
          max-width: 20px;
          @screen xl{
            max-width: auto;
          }
        }
      }
    }
    &__button{
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 12px;
      font-size: 18px;
      line-height: 24px;
      margin-top: 70px;
      width: calc(100% - 30px);
      @screen lg{
        width: calc(100% - 40px);
      }
      @media (min-width: 1920px){
        padding-top: 15px;
        padding-bottom: 18px;
        font-size: 22px;
        line-height: 28px;
        width: calc(100% - 80px);
      }
    }
  }
}