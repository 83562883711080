.detalle-body {
	@apply relative grid mx-auto;
	max-width: 1280px;
	grid-template-columns: 1fr;
	row-gap: 25px;
	gap: 50px;

	@screen lg {
		grid-template-columns: 7fr 5fr;
	}

	.detalle-body__carousel {
		@apply relative pt-8;

		.images {
			.carousel {
				.slide {
					@apply rounded-lg;

					height: 300px;
					max-width: 100%;
					img {
						@apply rounded-lg;
						height: 300px;
						max-width: 100%;
						object-fit: cover;
					}

					@screen sm {
						height: 400px;
						img {
							height: 400px;
						}
					}
					@screen md {
						height: 500px;
						img {
							height: 500px;
						}
					}
				}
				.thumbs-wrapper {
					max-width: 100%;
					margin: 20px 0 !important;

					.thumb {
						@apply rounded-lg;
						height: 80px;

						img {
							@apply rounded-lg;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
		.video {
			@apply w-full;
			video {
				@apply rounded-lg;

				width: 100%;
			}
		}

		.media-buttons {
			@apply absolute grid grid-cols-2 gap-2;
			top: 50px;
			left: 20px;
		}
		.navigation-buttons {
			@apply absolute grid grid-cols-2 gap-2;
			top: 50px;
			right: 20px;
		}
		.media-buttons,
		.navigation-buttons {
			.button-option {
				@apply flex items-center justify-center px-2 py-2 text-center rounded-full transition-all;
				color: $white-color;
				border: 2px solid $white-color;

				&--active {
					background-color: $white-color;
					color: $dark-color;
				}

				&:hover {
					background-color: $white-color;
					color: $dark-color;
				}
				&[disabled] {
					display: none;
				}
			}
		}
	}

	.detalle-body__info {
		@apply w-full h-full pt-8;

		.h2 {
			@apply mb-2;
			color: $dark-color;
		}
		.h3 {
			@apply mb-6;
			color: $light-color;
		}

		&__table {
			@apply w-full grid rounded-lg px-4 py-4 gap-0 mt-8 text-center transition-all;

			background-color: $white-color;
			grid-template-columns: 3fr 9fr;
			height: fit-content;

			.table-title {
				border-right: 1px solid $light-color;
				color: $light-color;
			}
			.table-value {
				color: $dark-color;
			}
			.table-title,
			.table-value {
				@apply py-4 px-2;
				border-top: 1px solid $light-color;

				&:first-child {
					border-top: none;
				}
				&:nth-child(2) {
					border-top: none;
				}
			}
		}
		&__button {
			@apply w-full mx-auto flex items-center justify-center px-8 py-3 text-center rounded-full transition-all mt-8;

			background-color: white;
			color: $dark-color;
			border: 2px solid $light-color;

			span {
				@apply mr-2;
			}

			&:hover {
				&:hover {
					filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
				}
			}
		}
		&__contact {
			@apply flex flex-wrap w-full justify-start mt-6;

			a {
				@apply w-fit flex items-center
        justify-center px-6 py-3 text-center rounded-full
        transition-all mr-8 my-2 text-white cursor-pointer;

				span {
					@apply pl-2;
				}

				&.b-whatsapp {
					background-color: #25d366;
				}
				&.b-call {
					background-color: $light-color;
				}

				&:hover {
					filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
				}
			}
		}
	}
	.detalle-body__form {
		@apply w-full pt-8;

		.form__title {
			color: $dark-color;
		}
		.form__container {
			@apply grid grid-cols-1 py-8;
			row-gap: 20px;
			column-gap: 20px;

			.form__input {
				@apply py-2 transition-all;
				border: none;
				border-bottom: 2px solid $light-color;
				font-family: 'VWHead Light';

				&:focus {
					outline: none;
					border-bottom: 2px solid $dark-color;
				}
				&:disabled {
					color: $gray-color;
				}
			}
			.form__button {
				@apply w-full px-6 py-3 text-center rounded-full
        transition-all mr-8 my-4 text-white cursor-pointer;
				background-color: $light-color;
				font-family: 'VWHead Light';

				&:hover {
					filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
				}
			}

			@screen lg {
				grid-template-columns: 1fr 1fr;

				.form__button {
					grid-column: 1 / span 2;
				}
			}
			@screen xl {
				grid-template-columns: 1fr 1fr 1fr;

				.form__button {
					grid-column: 1 / span 3;
				}
			}
		}
	}
}
