.informations{
  max-width: calc(100% - 60px);
  margin-top: 30px;
  margin-bottom: 30px;
  @screen md {
    max-width: 100%;
    @include padding(20px, 30px, 20px, 30px);
  }
  @screen lg {
    @include padding(40px, 60px, 40px, 60px);
    margin-top: 0;
    margin-bottom: 0;
  }
  @screen xl {
    @include padding(40px, 80px, 40px, 80px);
  }
  .carousel-root{
    @apply m-auto;
    .arrow-button{
      position: absolute;
      bottom: 35px;
      z-index: 1;
      &.prev{
        left: calc(50% - 38px);
      }
      &.next{
        left: calc(50% + 8px);
      }
    }
  }
  &__items{
    @include padding(40px, 40px, 100px, 40px);
    @screen md {
      @include padding(0, 0, 0, 0);
    }
    figure{
      width: 75px;
      height: 75px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 81.77%);
      margin-bottom: 15px;
      @screen md {
        margin-bottom: 0;
        margin-right: 10px;
        min-width: 75px;
      }
      @screen xl {
        margin-right: 15px;
      }
      img{
        width: 45px !important;
      }
    }
    .text{
      h4{
        margin-bottom: 12px;
        @screen md {
          margin-bottom: 7px;
        }
      }
      p{
        font-size: 14px;
        @screen md {
          font-size: 12px;
        }
        @screen lg {
          font-size: 14px;
        }
        @screen 2xl {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}