@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import './shared/reset';
@import './shared/globals';
@import './shared/mixins';
@import './components/vehicle.scss';
@import './components/calltoactions.scss';
@import './components/slider-informations.scss';
@import './components/related.scss';
@import './components/sell.scss';
@import './components/testimonials.scss';

@import './pages/home/index.scss';
@import './pages/detalle/index.scss';

@import './components/navbar.scss';
@import './components/vehicle.scss';
@import './components/footer.scss';

// de prueba
.App {
  position: relative;
  height: fit-content;
}
