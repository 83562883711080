.car{
  // min-width: 100%;
  @media (min-width: 768px){
    // min-width: 300px;
  }
  @screen lg{
    // @include padding(0, 15px, 0, 15px);
  }
  @screen 2xl{
    min-width: 350px;
  }
  @media (min-width: 1920px){
    min-width: 390px;
  }
  &__image{
    height: 220px;
    @media (min-width: 768px){
      height: 230px;
    }
    @media (min-width: 1536px){
      height: 280px;
    }
  }
  &__content{
    @include padding(25px, 30px, 25px, 30px);
    @screen md{
      @include padding(30px, 25px, 30px, 25px);
    }
    @screen xl{
      @include padding(35px, 30px, 35px, 30px);
    }
    @media (min-width: 1920px){
      @include padding(35px, 40px, 35px, 40px);
    }
    &__price{
      margin-bottom: 17px;
    }
    &__details{
      margin-top: 17px;
      li{
        margin-bottom: 18px;
        &:last-child{
          margin-bottom: 0;
        }
        .lavel{
          font-size: 12px;
          line-height: 14px;
          @media (min-width: 1536px){
            font-size: 16px;
            line-height: 17.6px;
          }
        }
        .value{
          font-size: 24px;
          line-height: 26px;
          @media (min-width: 1536px){
            font-size: 30px;
            line-height: 33px;
          }
        }
      }
    }
  }
  &__footer{
    @include padding(15px, 0, 15px, 0);
    font-size: 18px;
    @media (min-width: 1536px){
      @include padding(25px, 0, 25px, 0);
      font-size: 22px;
    }
  }
}
.block{
  display: block;
}