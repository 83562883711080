$dark-color: #001F4E;
$light-color: #099AC8;
$white-color: #F6F6F6;
$gray-color: #7D7D7D;
$gray-light-color: #D7D7D7;

.footer {
  @apply w-full relative;

  padding: 50px 0 20px 0;
  background-color: #001F4E;
  color: #fff;
  bottom: 0;

  &__top {
    @apply grid grid-cols-1;
    padding: 40px 0 60px 0;
    row-gap: 50px;

    &__logos {
      @apply flex flex-col justify-between items-start;

      .rrss {
        @apply flex pt-8;

        a {
          @apply w-8 h-8 mr-4;
        }
      }
    }
    &__promociones {
      input {
        background: transparent;
        border: 3px solid $light-color;
        border-radius: 50px;
        padding: 5px 20px;
        max-width: 350px;
        width: 100%;

        &::placeholder {
          color: $white-color;
          font-weight: 300;
          font-family: 'VWHead Light';
        }
      }
    }

    @screen lg {
      grid-template-columns: repeat(3, 1fr);

      &__logos {
        grid-column: 1 / span 3;
      }
    }
    @screen xl {
      grid-template-columns: repeat(4, 1fr);

      &__logos {
        grid-column: 1;
      }
      &__horarios {
        grid-column: 2;
      }
      &__contacto {
        grid-column: 3;
      }
      &__promociones {
        grid-column: 4;

        p {
          max-width: 350px
        }
      }
    }
  }

  &__bottom {
    @apply flex justify-between items-start;
    border-top: 1px solid #fff;
    padding: 20px 0 10px 0;

    img {
      @apply mx-auto w-10 h-10;
    }
  }
}